import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { Button } from "primereact/button";
import "./styles.css";
import headerNavList from "../../constants/header-navlist";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("location", pathname);

  return (
    <header className="w-full py-3 px-2 lg:px-6 m-auto App-header flex justify-content-between align-items-center sticky top-0 z-5 gap-3">
      <div className="logo-container">
        <img
          src={"/insuright_logo.svg"}
          alt="logo"
          onClick={() => navigate("/")}
          className="cursor-pointer"
        />
      </div>
      <div className="flex gap-3 align-items-center">
        <ul className="list-none gap-1 m-0 hidden lg:flex align-items-center">
          {headerNavList?.map((item, i) => (
            <li key={i} className={`nav-option cursor-pointer`}>
              {item.isExternal ? (
                // For external links, use <a> tag
                <a
                  href={item.route}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="header-link"
                >
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </a>
              ) : (
                // For internal links, use <Link> tag
                <Link to={item.route} className="header-link">
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
        {/* <span>
          <Button label="Login" className="login-button" />
        </span> */}
        <span>
          <Button
            className="humburger-btn"
            icon={<Menu width={16} height={16} />}
            text
          />
        </span>
      </div>
    </header>
  );
}
