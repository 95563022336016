const headerNavList = [
  {
    label: "Become a Posp",
    route: "https://web.insuright.co.in/pos/",
    icon: "pi pi-external-link",
    isExternal: true,
  },
  {
    label: "POSP login",
    route: "https://web.insuright.co.in/login.php",
    icon: "pi pi-external-link",
    isExternal: true,
  },
  // {
  //   label: "Customer login",
  //   route: "/customer-login",
  //   icon: "pi pi-external-link",
  // },
  {
    label: "Claim Assistance",
    route: "/claim-assistance",
    icon: "pi pi-external-link",
  },
  {
    label: "Contact us",
    route: "/contact-us",
    icon: "",
  },
];

export default headerNavList;
